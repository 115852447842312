$('#select-consorcio-versao').on('change', function () {
  window.location = root_rel + 'consorcio/' + $(this).val();
});

$('.js-click-modal-open, a[rel="modal:open"]').on('click', function () {
  var modal_id = $(this).attr('href');

  if (!$(modal_id).length) {
    return true;
  }

  var assunto = $(this).data('id_assunto');
  var id_carro = $(this).data('id_carro');
  var id_veiculo = $(this).data('id_veiculo');
  var id_oferta_imperdivel = $(this).data('id_oferta_imperdivel');
  var id_carro_versao = $(this).data('id_carro_versao');
  var id_pecaacessorio = $(this).data('id_pecaacessorio');
  var interesse = $(this).data('interesse');

  if (assunto && $(modal_id).find('#form-id_assunto').length) {
    $(modal_id).find('#form-id_assunto').val(assunto);
  }

  if (id_veiculo && $(modal_id).find('#form-id_carro').length) {
    $(modal_id).find('#form-id_carro').val(id_veiculo);
  }
  if (id_oferta_imperdivel && $(modal_id).find('#form-id_oferta_imperdivel').length) {
    $(modal_id).find('#form-id_oferta_imperdivel').val(id_oferta_imperdivel);
  }

  if (id_carro && $(modal_id).find('#form-id_carro').length) {
    $(modal_id).find('#form-id_carro').val(id_carro);
  }
  if (id_carro_versao && $(modal_id).find('#form-id_carro_versao').length) {
    $(modal_id).find('#form-id_carro_versao').val(id_carro_versao);
  }
  if (id_pecaacessorio && $(modal_id).find('#form-id_pecaacessorio').length) {
    $(modal_id).find('#form-id_pecaacessorio').val(id_pecaacessorio);
  }

  if (interesse && $(modal_id).find('#form-interesse').length) {
    $(modal_id).find('#form-interesse').val(interesse);
  }

});
function clickBanner(id_banner) {
  $.ajax({
    url: root_rel + 'lua4auto/banners/click/' + id_banner,
    type: 'get'
  });
}


$('.btn-banner-oferta').on('click', function (event) {
  var id_banner = $(this).data('id_banner');
  clickBanner(id_banner);
  //      $('#id_banner-ofertas').val(id_banner);
});

$('.btn-banner-click').on('click', function (event) {
  var id_banner = $(this).data('id_banner');
  clickBanner(id_banner);
});



// Slider

$("body").on('init', '.slider-principal', function () {
  $('.slider-principal').css('visibility', 'visible');
});

$('.slider-principal').slick({
  adaptiveHeight: true,
  infinite: true,
  autoplay: true,
  pauseOnFocus: false,
  arrows: true,
  dots: true,
  prevArrow: $('.seta-esquerda'),
  nextArrow: $('.seta-direita'),
});

$(".seta-esquerda").click(function () {
  $(".slider-principal").slick("slickPrev")
});
$(".seta-direita").click(function () {
  $(".slider-principal").slick("slickNext")
});

// Slide Venda Direta

$('.slide-venda-direta').slick({
  dots: false,
  infinite: true,
  speed: 300,
  arrows: true,
  prevArrow: $('.seta-slider-venda-direta-esquerda'),
  nextArrow: $('.seta-slider-venda-direta-direita'),
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        arrows: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});

// Seleção Carros

$('.slider-de-carros').slick({
  dots: false,
  infinite: true,
  speed: 300,
  arrows: true,
  prevArrow: $('.seta-slider-de-carros-esquerda'),
  nextArrow: $('.seta-slider-de-carros-direita'),
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        arrows: false,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});


// Slider Simule Seu Carro

$('.slider-simule').slick({
  dots: false,
  infinite: true,
  speed: 300,
  arrows: true,
  prevArrow: $('.seta-slider-simule-esquerda'),
  nextArrow: $('.seta-slider-simule-direita'),
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        arrows: false,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});


// Slider De Peças

$('.slider-peca').slick({
  dots: false,
  infinite: true,
  speed: 300,
  arrows: true,
  prevArrow: $('.seta-peca-esquerda'),
  nextArrow: $('.seta-peca-direita'),
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        arrows: false,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});


// Slider De Seminovos

$('.slider-seminovos').slick({
  dots: false,
  infinite: true,
  speed: 300,
  arrows: true,
  prevArrow: $('.seta-semi-esquerda'),
  nextArrow: $('.seta-semi-direita'),
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        arrows: false,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});



// Slide Adicionais

$('.slide-adicionais').slick({
  dots: false,
  infinite: true,
  speed: 300,
  arrows: true,
  prevArrow: $('.seta-adicionais-esquerda'),
  nextArrow: $('.seta-adicionais-direita'),
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        arrows: false,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});



$(".botao-mais-infos").click(function () {
  $(this).parent().children(".box-mais-info").slideToggle();
  $(this).toggleClass("mais-ativo");
});


$(".ver-itens-adicional").click(function () {
  $(this).parent().children(".adicionais-do-carro").slideToggle(function () {
    if ($(this).css('display') == 'block') {
      $(this).parent('.card-adicional').addClass('adicional-ativo');
    } else {
      $(this).parent('.card-adicional').removeClass('adicional-ativo');
    }
  });
});



// Slide Versoes

$('.versao').slick({
  dots: false,
  infinite: true,
  speed: 300,
  arrows: true,
  prevArrow: $('.seta-versao-esquerda'),
  nextArrow: $('.seta-versao-direita'),
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});


// Slide Outro

$('.outro-slider').slick({
  dots: true,
  infinite: true,
  speed: 300,
  arrows: true,
  prevArrow: $('.seta-outro-esquerda'),
  nextArrow: $('.seta-outro-direita'),
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});

// Ultimo Slider

$('.ultimo-slider').slick({
  dots: false,
  infinite: true,
  speed: 300,
  arrows: true,
  prevArrow: $('.seta-ultimo-esquerda'),
  nextArrow: $('.seta-ultimo-direita'),
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});




$('.interior').click(function () {
  $(this).addClass('ativo-in-ex');
  $('.exterior').removeClass('ativo-in-ex');
  $('.fotos-interior').fadeIn();
  $('.fotos-exterior').hide();

});

$('.exterior').click(function () {
  $(this).addClass('ativo-in-ex');
  $('.interior').removeClass('ativo-in-ex');
  $('.fotos-exterior').fadeIn();
  $('.fotos-interior').hide();
});



// Slider Galeria

$('.galeria-fotos').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: false
  //    asNavFor: '.pager-galeria'
});

$('.pager-galeria').slick({
  slidesToShow: 8,
  slidesToScroll: 8,
  asNavFor: '.galeria-fotos',
  dots: false,
  arrows: false,
  focusOnSelect: true
});

$('.form-ajax').ajaxForm({
  dataType: 'json',
  beforeSubmit: function (arr, $form, options) {
    $('.submit-action').attr('disabled', 'disabled');
  },
  success: function (json, status, xhr, form) {
    var id_form = form[0].id;

    if (json.erro != "") {
      alertify.alert(json.erro);
    } else {
      alertify.alert(json.sucesso);
      $('#' + id_form)[0].reset();
    }
  },
  error: function () {
    alertify.alert("Erro ao processar formulário.<br>Tente novamente mais tarde.");
  },
  complete: function () {
    $('.submit-action').removeAttr('disabled');
  }
});

$(".mask--ano").mask("9999");
$(".mask--data").mask("99/99/9999");
$(".mask--cep").mask("99999-999");
$(".mask--cpf").mask("999.999.999-99");
$(".mask--cnpj").mask("99.999.999/9999-99");
$(".mask--placa").mask("aaa-9999");
$('.mask--telefone')
  .mask("(99)9999-9999?9", { placeholder: "" })
  .blur(function (event) {
    var target, phone, element;
    target = (event.currentTarget) ? event.currentTarget : event.srcElement;
    phone = target.value.replace(/\D/g, '');
    element = $(target);
    element.unmask();
    if (phone.length > 10) {
      element.mask("(99)99999-999?9", { placeholder: "" });
    } else {
      element.mask("(99)9999-9999?9", { placeholder: "" });
    }
  });

$("#cpf_cnpj").unmask();
$("#cpf_cnpj").focusout(function () {
  $("#cpf_cnpj").unmask();
  var tamanho = $("#cpf_cnpj").val().replace(/\D/g, '').length;
  if (tamanho == 11) {
    $("#cpf_cnpj").mask("999.999.999-99");
  } else if (tamanho == 14) {
    $("#cpf_cnpj").mask("99.999.999/9999-99");
  }
});
$(".cpfCnpj").focusin(function () {
  $(".cpfCnpj").unmask();
});
$('.mask--money').maskMoney({
  prefix: 'R$ ',
  thousands: '.',
  decimal: ',',
  allowZero: true,
  allowEmpty: true,
});

$('.clique-menu').click(function () {
  $('.box-menu-mobile').slideDown('slow');
});

$('.clique-fechar').click(function () {
  $('.box-menu-mobile').slideUp();
});

$('.carro-sub-menu').click(function () {
  $('.sub-carro').slideToggle('slow');
  $('.sub-pos').slideUp('slow');
});

$('.pos-sub-menu').click(function () {
  $('.sub-pos').slideToggle('slow');
  $('.sub-carro').slideUp('slow');
});

$(".submenu-toggle").on("click", function () {
  $(this).siblings('.sub-menu').slideToggle("slow");
  if (!$(this).siblings(".sub-menu").hasClass("active")) {
    $(".sub-menu.active").slideUp("slow").removeClass("active");
    $(this).siblings(".sub-menu").addClass("active");
  }
});
